import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDrivers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/drivers')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobDriver(ctx, { jobId, driverId }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/driver`, { driverId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      const {
        id, email, firstName, lastName, phoneNumber, role, status,
      } = userData
      const newUserData = {
        email, firstName, lastName, phoneNumber, role, status,
      }
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}`, newUserData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
